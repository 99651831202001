import { useEffect, useState } from 'react';

import { showErrorToast } from '@features/toast';

import { getPersistentAuthData } from '@hooks/useAuth/utils';
import { useDebouncedValue } from '@hooks/useDebouncedValue';

import {
  ApiError,
  ApiStatus,
  FetchArgs,
  FetchData,
  FetchMethod,
  FetchOptions,
  FetchResponse,
  FetchStatus,
  Headers
} from '@app-types/api';
import { isArray } from '@utils/general';
import axios, { AxiosError } from 'axios';

export type FetchFnReset = () => void;

interface FetchFnCallArgs {
  method: FetchMethod;
  url: string;
  data?: any;
  headers?: Headers;
}

export type FetchFnCall<Data = unknown> = (
  args: FetchFnCallArgs | Array<FetchFnCallArgs>,
  options?: Omit<FetchOptions<Data>, 'fetchWhenMount'>
) => void;
export type UseFetchReturn<Data = unknown> = [
  FetchData<Data>,
  FetchStatus,
  FetchFnCall<Data>,
  FetchFnReset
];

export const useFetch = <Data = any>(): UseFetchReturn<Data> => {
  const [response, setResponse] = useState<FetchData<Data>>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [status, setStatus] = useState<ApiStatus>('NOT_STARTED');
  const [wasCalled, setWasCalled] = useState<boolean>(false);

  const debouncedStatus = useDebouncedValue<ApiStatus>(status, 100);

  useEffect(() => {
    if (debouncedStatus === 'SUCCESS') {
      setStatus('NOT_STARTED');
    }
  }, [debouncedStatus]);

  const handleReset = () => {
    setResponse(null);
    setError(null);
    setStatus('NOT_STARTED');
    setWasCalled(false);
  };

  const handleFetch: FetchFnCall<Data> = async (args, options) => {
    const { onAfterSuccess, onAfterFailed, onResponseProcessing, useNativeFetch } = options || {};

    const handleFailure = (message: string | undefined) => {
      const apiError: ApiError = {
        message: message || 'Something went wrong'
      };

      if (message) {
        showErrorToast(message);
      }
      onAfterFailed?.(apiError);
      setResponse(null);
      setError(apiError);
      setStatus('FAILED');
      setWasCalled(true);
    };

    const handleSuccess = (result: Data, message: string) => {
      setResponse({
        message,
        result
      });

      onAfterSuccess?.({
        message,
        result
      });
      setStatus('SUCCESS');
      setWasCalled(true);
    };

    try {
      setStatus('BUSY');

      const { authData } = await getPersistentAuthData();
      const token = authData?.token;

      const handleCall = async (args: FetchArgs): Promise<FetchResponse<Data | null>> => {
        const { method, url, data, headers = {} } = args;

        let out = null;

        if (useNativeFetch) {
          out = await fetch(url);
        } else {
          out = (
            await axios({
              url,
              method,
              data,
              headers: {
                Authorization: token && `Bearer ${token}`,
                ...headers
              }
            })
          ).data;
        }

        let result = null;
        let message = '';

        if (out.result !== undefined && out.message !== undefined) {
          result = out.result;
          message = out.message;
        } else {
          result = out;
          message = '';
        }

        if (result === null || result === false) {
          /**
           * The api return result null or false when fail
           */
          return {
            message,
            result: null
          };
        }

        if (onResponseProcessing) {
          return await onResponseProcessing?.({ result, message });
        } else {
          return { message, result };
        }
      };

      const argsArray = isArray(args) ? args : [args];

      const promises = argsArray.map((args) => {
        return new Promise<FetchResponse<Data | null>>((resolve, reject) => {
          handleCall(args).then(resolve).catch(reject);
        });
      });

      const responses = await Promise.all(promises);

      if (isArray(args)) {
        handleSuccess(
          //@ts-expect-error ignore
          responses.map(({ result }) => result),
          '' //TODO fix the right message when is array
        );
      } else {
        const [{ message, result }] = responses;

        if (!result) {
          return handleFailure(message);
        }

        handleSuccess(result, message);
      }
    } catch (e) {
      const { response } = e as AxiosError<{ message?: string }>;

      handleFailure(response?.data?.message);
    }
  };

  return [
    response,
    {
      isNotStarted: status === 'NOT_STARTED',
      isBusy: status === 'BUSY',
      isFailed: status === 'FAILED',
      isSuccess: status === 'SUCCESS',
      error,
      wasCalled
    },
    handleFetch,
    handleReset
  ];
};
