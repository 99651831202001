import { useApiPersistent } from '@features/slices/useApiPersistent';

import { resetPersistentAuthData, setPersistentAuthData } from './utils';

import { useAuthSignIn } from '@api/auth/useAuthSignIn';
import { FetchResource } from '@app-types/api';
import { AuthData } from '@app-types/auth';
import { wait } from '@utils/general';

type UseAuthMeta = {
  authSignIn: FetchResource<{ username: string; password: string }, AuthData>;
  onSignOut: () => void;
  authData: AuthData | undefined;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isAuthenticated: boolean;
};

export type UserAuthReturn = ReturnType<typeof useAuthSignIn> & UseAuthMeta;

export const useAuth = (): UserAuthReturn => {
  const { authSignIn } = useAuthSignIn();

  const { data, status, fetch, reset } = useApiPersistent('useAuth', authSignIn);

  const rolename = data?.result.roleName;

  return {
    isSuperAdmin: rolename === 'SuperAdmin',
    isAdmin: rolename === 'Admin',
    isAuthenticated: !!data?.result,
    onSignOut: () => {
      resetPersistentAuthData();
      reset();
    },
    authData: data?.result,
    authSignIn: {
      data,
      status,
      fetch: ({ password, username }, options = {}) => {
        fetch(
          {
            username,
            password
          },
          {
            ...options,
            onAfterSuccess: async (response) => {
              setPersistentAuthData({
                authData: response.result
              });
              await wait(100);
              options?.onAfterSuccess?.(response);
            }
          }
        );
      },
      reset: () => {
        resetPersistentAuthData();
        reset();
      }
    }
  };
};
