import { Menu } from '@components/menu';

import { useRouter } from '@features/router/useRouter';

import { useAuth } from '@hooks/useAuth';
import { useBreakpoints } from '@hooks/useBreakpoints';

import { NavBarLayout } from '@pages/@common/nav-bar/nav-bar-layout';

import LOGO from '@assets/logo.png';

import { NavBarTop } from './nav-bar-top';

import { StyleProps } from '@app-types/general';
import SvgBarsSolid from '@icons/BarsSolid';

export interface NavbarProps extends StyleProps {}
export const Navbar = ({ className }: NavbarProps) => {
  const { pushRoute } = useRouter();
  const { isAdmin, isSuperAdmin, isAuthenticated } = useAuth();
  const breakpoints = useBreakpoints();
  return (
    <div>
      <NavBarTop />
      <NavBarLayout
        className={className}
        preContent={
          <div className="cursor-pointer size-14 mx-5 lg:mx-0" onClick={() => pushRoute('/')}>
            <img src={LOGO} alt="USRoof" />
          </div>
        }
        items={[
          { href: '/', name: 'Home' },
          isAuthenticated && { href: '/solar-designs', name: 'My Designs' },
          { href: '/blog', name: 'Blog' },
          { href: '/faq', name: 'Faq' },
          { href: '/contactus', name: 'Contact Us' },
          { href: '/aboutus', name: 'About Us' },
          { href: '/solar-designs/roof-analysis', name: 'Roof Analysis' },
          (isSuperAdmin || isAdmin) && { href: '/admin', name: 'Admin' }
        ]}
        postContent={
          (breakpoints.xs || breakpoints.sm || breakpoints.md) && (
            <Menu
              buttonElement={<SvgBarsSolid className="size-7 mx-5 fill-gray-200" />}
              items={[
                { label: 'My Designs', onClick: () => pushRoute('/solar-designs') },
                { label: 'Blog', onClick: () => pushRoute('/blog') },
                { label: 'Faq', onClick: () => pushRoute('/faq') },
                { label: 'Contact Us', onClick: () => pushRoute('/contactus') },
                { label: 'About Us', onClick: () => pushRoute('/aboutus') },
                {
                  label: 'Roof Analysis',
                  onClick: () => pushRoute('/solar-designs/roof-analysis')
                },
                (isSuperAdmin || isAdmin) && {
                  label: 'Admin',
                  onClick: () => pushRoute('/admin')
                }
              ]}
              className="lg:hidden"
            />
          )
        }
      />
    </div>
  );
};
